<template>
  <v-card class="card-shadow border-radius-xl mt-2 pb-2">
    <common-loading
      :title="loading.title"
      :message="loading.message"
      :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading"
      :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"
    ></common-loading>
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>
    <div class="card-header-padding">
      <div class="d-flex align-center">
        <div>
          <h5 class="font-weight-bold text-h5 text-grt mb-0">變更密碼</h5>
        </div>
        <v-btn
          color="#cb0c9f"
          class="
            font-weight-bolder
            btn-default
            bg-gradient-default
            py-4
            px-4
            ms-auto
            mt-sm-auto mt-4
          "
          @click="confirm_change_password"
        >
          變更密碼
        </v-btn>
      </div>
    </div>

    <div class="px-6 pb-2 pt-0">
      <v-form>
        <v-row>
          <v-col cols="8" class="pt-0">
            <v-row>
              <v-col cols="12">
                <label class="text-md font-weight-bolder ms-1"
                  >目前密碼</label
                >
                <v-text-field
                  autocomplete="current-password"
                  v-model="password"
                  hide-details
                  outlined
                  placeholder="原有密碼"
                  class="
                    font-size-input
                    placeholder-lighter
                    border border-radius-md
                    mt-2
                  "
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="text-md font-weight-bolder ms-1"
                  >新密碼</label
                >
                <v-text-field
                  autocomplete="new-password"
                  v-model="new_password_1"
                  hide-details
                  outlined
                  placeholder="新密碼"
                  class="
                    font-size-input
                    placeholder-lighter
                    border border-radius-md
                    mt-2
                  "
                  :append-icon="showPassword_1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword_1 = !showPassword_1"
                  :type="showPassword_1 ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="text-md font-weight-bolder ms-1"
                  >確認新密碼</label
                >
                <v-text-field
                  autocomplete="new-password-2"
                  v-model="new_password_2"
                  hide-details
                  outlined
                  placeholder="確認新密碼"
                  class="
                    font-size-input
                    placeholder-lighter
                    border border-radius-md
                    mt-2
                  "
                  :append-icon="showPassword_2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword_2 = !showPassword_2"
                  :type="showPassword_2 ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <h5 class="mt-2 text-h5 font-weight-bold">
              密碼要求
            </h5>
            <p class="text-muted mb-2">請讓密碼符合以下規則</p>
            <div class="d-sm-flex">
              <ul class="text-muted ps-6 mb-4">
                <li>
                  <span class="text-md">最少六個字元</span>
                </li>
                <!-- <li>
                  <span class="text-sm">至少一個數字</span>
                </li> -->
                <li>
                  <span class="text-md">經常變更密碼</span>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
export default {
  name: "user-change-password",
  data() {
    return {
      password: null,
      new_password_1: null,
      new_password_2: null,
      showPassword: false,
      showPassword_1: false,
      showPassword_2: false,
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
    };
  },
  components: {
    CommonLoading,
    CommonConfirm,
  },
  methods: {
    ...mapGetters(["getToken"]),
    reset() {
      this.password = null;
      this.new_password_1 = null;
      this.new_password_2 = null;
      this.showPassword = false;
      this.showPassword_1 = false;
      this.showPassword_2 = false;
    },
    confirm_change_password() {
      if (
        this.new_password_1 == null ||
        this.new_password_1 != this.new_password_2
      ) {
        this.loading = {
          dialog: true,
          title: "密碼錯誤",
          message: "新密碼 確認新密碼不相符",
          show_loading: false,
          show_confirm: true,
        };
        return;
      }
      if (this.new_password_1.length < 6) {
        this.loading = {
          dialog: true,
          title: "密碼錯誤",
          message: "密碼長度至少六個字元",
          show_loading: false,
          show_confirm: true,
        };
        return;
      }
      // confirm
      this.confirm = {
        dialog: true,
        title: "更新案件",
        message: "確認更新密碼?",
        function: this.change_password,
      };
    },
    change_password() {
      console.log("update the user profile");
      this.confirm.dialog = false;
      const token = this.getToken();
      const currentObj = this;
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/change_password`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      let data = {
        password: this.password,
        new_password: this.new_password_1,
      };
      this.loading = {
        dialog: true,
        title: "更新中",
        message: "請稍後",
        show_loading: true,
        show_confirm: false,
      };
      this.axios
        .put(url, data, config)
        .then(function (response) {
          currentObj.loading = {
            dialog: true,
            title: "更新成功",
            message: "請用新密碼登入",
            show_loading: false,
            show_confirm: true,
          };
          // TODO 是否主動登出？
          currentObj.$emit("password-changed")
          
        })
        .catch(function (error) {
          console.log(error.response);
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            if (
              error.response.data.detail.includes("Incorrect email or password")
            ) {
              currentObj.loading = {
                dialog: true,
                title: "密碼錯誤",
                message: "請檢查密碼",
                show_loading: false,
                show_confirm: true,
              };
            }
          }
        })
        .finally(() => {
          currentObj.reset();
        });
    },
  },
};
</script>
