<template>
  <v-container fluid class="pt-2">
    <v-row >
      <v-col>
        <v-card class="card-shadow px-4 py-2 overflow-hidden border-radius-xl">
          <v-row>
            <v-col cols="auto">
              <v-avatar width="60" height="60" class="shadow border-radius-lg">
                <img
                  src="@/assets/img/team-3.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100">
                <h5 class="mb-1 text-h5 font-weight-bold">
                  {{ profile.last_name }}{{ profile.first_name }}
                </h5>
                <p class="mb-0 font-weight-bold text-body text-sm">
                  {{ profile.job_title }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <user-basic-info :user_profile="profile"></user-basic-info>
        <theme-mode @updateSidebarTheme="updateSidebarTheme"></theme-mode>
        <change-password></change-password>
        <!-- <two-factor></two-factor>
        <accounts></accounts>
        <notifications></notifications>
        <sessions></sessions> -->
        <!-- <delete-account></delete-account> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserBasicInfo from "./Widgets/UserBasicInfo.vue";
import ChangePassword from "./Widgets/UserChangePassword.vue";
// import ThemeMode from "./Widgets/ThemeMode.vue";
// import TwoFactor from "./Widgets/TwoFactor.vue";
// import Accounts from "./Widgets/Accounts.vue";
// import Notifications from "./Widgets/Notifications.vue";
// import Sessions from "./Widgets/Sessions.vue";
// import DeleteAccount from "./Widgets/DeleteAccount.vue";

export default {
  name: "Settings",
  components: {
    UserBasicInfo,
    ChangePassword,
    ThemeMode: () => import("@/views/Pages/CRM/Widgets/ThemeMode.vue"),
    // TwoFactor,
    // Accounts,
    // Notifications,
    // Sessions,
    // DeleteAccount,
  },
  data() {
    return {
      profile: {},
    };
  },
  methods: {
    ...mapGetters(["getToken"]),
    getProfile() {
      const token = this.getToken();
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/me`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };

      const currentObj = this;
      currentObj.loading = true;
      currentObj.result_display = true;
      this.axios
        .get(url, config)
        .then((response) => {
          currentObj.profile = response.data;
          // console.log("user ", currentObj.profile);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSidebarTheme(value) {
      this.$emit("updateSidebarTheme", value);
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>
